.nav-navbar {
  max-width: 1200px;
  margin: 0 auto;
  width: 100vw;
  padding: 35px;
  background-color: transparent;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.link-navbar {
  color: #ffffff;
  list-style: none;
}

.list-navbar {
  display: flex;
  justify-content: flex-end;
}

.list-navbar ul {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  align-items: center;
}

.link-navbar {
  padding: 5px 15px;
  cursor: pointer;
  text-decoration: none;
}

.link-navbar-sair {
  padding: 5px 15px;
  cursor: pointer;
  text-decoration: none;
  background-color: #ffffff;
  border-radius: 5px;
}

.link-navbar-sair li {
  font-size: 16px;
}

.link-navbar-sair:hover {
  background-color: #959595;
}

.link-navbar-sair {
  color: #11101d;
  list-style: none;
}

.icon-navbar {
  display: inline;
  justify-content: center;
}

#logo-principal {
  width: 137px;
  height: 53px;
}

.hamburguer {
  display: none;
}

.hamburger-menu-content {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(45deg, #055f89, #0187c6);
  z-index: 2;
}

.hamburger-react {
  z-index: 5;
}

.links-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding-top: 100px;
}

.links-mobile a {
  font-size: 1.1rem;
  color: #ffffff;
}

@media (max-width: 800px) {
  .nav-navbar ul {
    display: none;
  }

  .nav-navbar {
    display: flex;
    grid-template-columns: 1fr;
    justify-content: space-between;
  }

  .hamburguer {
    display: inline;
  }
}
