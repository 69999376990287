@keyframes fadein {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.container {
  background: linear-gradient(45deg, #055f89, #0187c6);
  background-repeat: no-repeat;
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.subContainer {
  background: white;
  max-width: 350px;
  min-height: 40vh;
  padding: 4rem;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  animation: fadein 0.5s;
}

.error h3 {
  padding-bottom: 2rem;
}

.botao {
  margin-top: 2rem;
  font-size: 15px;
  background: #055f89;
  color: #ffffff;
  padding: 10px 15px;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  border-radius: 5px;
}

.botao:hover {
  margin-top: 2rem;
  font-size: 15px;
  background: #0187c6;
  color: #ffffff;
  padding: 10px 15px;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
}

@media (max-width: 600px) {
  .subContainer {
    padding: 3rem;
  }
  .error h3 {
    font-size: 1rem;
    padding-bottom: 1rem;
  }
  .error p {
    font-size: 0.8rem;
  }
  .botao {
    margin-top: 1rem;
    font-size: 0.8rem;
  }
}
