@keyframes fadein {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.container {
  background: linear-gradient(45deg, #055f89, #0187c6);
  background-repeat: no-repeat;
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.subContainer {
  background: white;
  padding: 4rem;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  animation: fadein 0.5s;
}

.formRegister {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.inputs {
  position: relative;
}

.formRegister input[type="submit"] {
  margin-top: 1.5rem;
  padding: 0.4rem;
  background: #055f89;
  cursor: pointer;
  color: white;
  font-size: 1rem;
  font-weight: 300;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.formRegister input[type="submit"]:hover {
  background: #0187c6;
}

.buttonBack button {
  display: block;
  margin-top: 10px;
  padding: 0.4rem;
  background: #055f89;
  cursor: pointer;
  color: white;
  font-size: 1rem;
  font-weight: 300;
  border-radius: 5px;
  transition: all 0.3s ease;
  width: 100%;
}

.buttonBack:hover {
  background-color: #0187c6;
  border-radius: 5px;
}

.logoAzul {
  display: flex;
  justify-content: center;
}

.logoAzul img {
  width: 200px;
  height: 78px;
  margin-bottom: 28px;
}
