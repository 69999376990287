.container {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 65vh;
}

.container p {
  font-size: 1.2rem;
}

.container p,
h1 {
  color: #000000;
}

.card {
  border-radius: 10px;
  box-shadow: 3px 3px 13px 0px rgba(50, 50, 50, 0.22);
  padding: 30px;
  margin: 20px;
  width: 550px;
  transition: all 0.3s ease-out;
  border-left: 5px solid #33ae40;
  background-color: white;
}

.card:hover {
  transform: translateY(-10px);
  cursor: pointer;
}

@media (max-width: 600px) {
  .container {
    margin-top: 0rem;
    height: 40vh;
  }
  .card:hover {
    transform: translateY(0px);
  }
  .card p {
    font-size: 1rem;
  }
  .card h1 {
    font-size: 1.5rem;
  }
}
