@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  margin: 0;
  border: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

#root {
  width: 100%;
  height: 100%;
}

body {
  background-image: url(./img/banner-principal.png);
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-color: #035d84;
}

html {
  overflow-x: hidden;
  width: 100%;
  height: 100%;
}

/*Removendo barra de rolar*/
::-webkit-scrollbar {
  width: 0px;
}

.link {
  text-decoration: none;
  color: #11101d;
}

a {
  text-decoration: none;
}

/* Removendo seletor lateral do tipo number no chrome e outros navegadores */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Removendo seletor lateral do tipo number no firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input[type="file"] {
  display: none;
}

/*Animação de opacidade e aumento*/
@keyframes fadein {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.row {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (max-width: 1175px) {
  .row {
    display: flex;
    flex-direction: column;
  }
}

/* Removendo bordas IOS */
textarea,
input[type="text"],
input[type="button"],
input[type="submit"],
input[type="reset"],
input[type="search"] {
  -webkit-appearance: none;
  border-radius: 5px;
}

/* Removendo style do Material UI */

.MuiTableCell-root {
  border: none !important;
  box-shadow: 0px 1px 0px 0px #e7e7e7 !important;
}

.MuiTableContainer-root {
  box-shadow: none !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
  background-color: #1875d13e !important;
}

.css-1km1ehz.Mui-selected {
  background-color: #1875d13e !important;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset !important;
}

.rec.rec-dot {
  background-color: #e0e0e0d6;
  content: "";
  height: 10px;
  width: 10px;
  box-shadow: none;
  border-radius: 50%;
  outline: none;
}

.rec.rec-dot_active {
  background-color: #a1a1a1d6;
}

button.rec-dot:hover,
button.rec-dot:active,
button.rec-dot:focus {
  box-shadow: none;
}

@media (max-width: 1175px) {
  .card-introducao {
    width: 90vw;
  }
}

@media (max-width: 600px) {
  body {
    background-size: 250% auto;
  }
}
